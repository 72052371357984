import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ConfigService } from '../../services/config/config.service';

@Injectable({
    providedIn: 'root',
})
export class UnsubscribeService {
    private baseUrl: string;
    constructor(
        private httpClient: HttpClient,
        private configService: ConfigService
    ) {
        this.baseUrl = `${this.configService.config.dsiMessagingServiceUrl}/api/v1`;
    }

    public unsubscribe(guid: string): Observable<string> {
        const request = { EmailGuid: guid };
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        };
        return this.httpClient.post<UnsubscribeResponse>(`${this.baseUrl}/Email/Unsubscribe`, request, options).pipe(
            map((response: UnsubscribeResponse) => {
                return response.Email;
            })
        );
    }
}

export interface UnsubscribeRequest {
    EmailGuid: string;
}

export interface UnsubscribeResponse {
    Email: string;
}
