import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { UnsubscribeComponent } from './components/unsubscribe/unsubscribe.component';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, UnsubscribeComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent {}
