import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from './constants';

export interface IConfig {
    production: boolean;
    env: string;
    dsiMessagingServiceUrl: string;
}

@Injectable()
export class ConfigService {
    private cachedConfig!: IConfig;

    public get config(): IConfig {
        return this.cachedConfig;
    }

    constructor(@Inject(APP_CONFIG) config: IConfig) {
        this.cachedConfig = config;
    }
}
