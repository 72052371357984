import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UnsubscribeService } from './unsubscribe.service';

@Component({
    selector: 'app-unsubscribe',
    standalone: true,
    imports: [CommonModule, MatProgressSpinnerModule],
    templateUrl: './unsubscribe.component.html',
    styleUrl: './unsubscribe.component.scss',
})
export class UnsubscribeComponent implements OnInit {
    constructor(
        private unsubscribeService: UnsubscribeService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    public email: string | undefined;
    public loading: boolean = true;

    ngOnInit() {
        this.loading = true;
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.getQueryParam();
            }
        });
    }

    private getQueryParam() {
        this.route.queryParamMap.subscribe((params) => {
            const emailguid = params.get('emailguid');
            if (emailguid) {
                this.unsubscribeService.unsubscribe(emailguid).subscribe({
                    next: (email: string) => {
                        this.email = email;
                        this.loading = false;
                    },
                    error: (error: any) => {
                        this.showError();
                    },
                });
            } else {
                this.showError();
            }
        });
    }

    private showError() {
        this.email = '';
        this.loading = false;
    }
}
