import { enableProdMode } from '@angular/core';
import { provideRouter } from '@angular/router';

import { provideHttpClient } from '@angular/common/http';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { ConfigService } from './app/services/config/config.service';
import { APP_CONFIG, CONFIG_URL } from './app/services/config/constants';

function configListener(event: ProgressEvent<EventTarget>) {
  try {
    const request = event.target as XMLHttpRequest;
    const configuration = JSON.parse(request.responseText);
    if (configuration?.production) {
      enableProdMode();
    }

    // pass config to bootstrap process using an injection token
    bootstrapApplication(AppComponent, {
      providers: [
        { provide: APP_CONFIG, useValue: configuration },
        ConfigService,
        provideRouter(routes),
        provideHttpClient(),
        provideAnimationsAsync('animations'),
      ],
    }).catch((err: Error) => console.error(err));
  } catch (error) {
    console.error(error);
  }
}

function configFailed(evt: Event) {
  console.error('Error: retrieving config.json', evt);
}
const request = new XMLHttpRequest();
request.addEventListener('load', configListener);
request.addEventListener('error', configFailed);
request.open('GET', CONFIG_URL);
request.send();
