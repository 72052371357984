<div class="unsubscribe-page">
    <div *ngIf="loading">
        <mat-spinner class="spinner" [diameter]="80"></mat-spinner>
    </div>
    <div *ngIf="!loading">
        <img alt="" [src]="'assets/logo_envelope.svg'" id="promoImage" />
        <ng-container *ngIf="email; then successMessage; else errorMessage"></ng-container>
        <ng-template #errorMessage>
            <div class="header-content">Sorry!</div>
            <div class="content">
                An error occurred while attempting to unsubscribe your email address. Please try again, or email us at
                support&#64;daysmart.com if you continue to receive this error.
            </div>
        </ng-template>
        <ng-template #successMessage>
            <div class="header-content">Goodbye!</div>
            <div class="content">
                {{ email }} has been successfully unsubscribed from marketing emails. You will no longer receive this type of email but if
                you are opted into appointment reminders, you will continue to receive those email notifications.
            </div>
        </ng-template>
    </div>
</div>
